import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css"; // Khôi phục Bootstrap CSS
import "bootstrap-icons/font/bootstrap-icons.css"; // Khôi phục Bootstrap Icons CSS
import "./App.css";

// Log khởi động ứng dụng
console.log(`[${new Date().toISOString()}] Khởi động ứng dụng Savinashop`);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);