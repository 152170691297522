import React, { useEffect, useState, Suspense, lazy, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google"; // Thêm import
import {
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import Header from "./components/Header";
import toast, { Toaster } from "react-hot-toast";
import zaloIcon from "./img/zalo.png";
import "./App.css";

const Home = lazy(() => import("./components/Home"));
const ProductDetail = lazy(() => import("./components/ProductDetail"));
const Cart = lazy(() => import("./components/Cart"));
const OrderHistory = lazy(() => import("./components/OrderHistory"));
const Admin = lazy(() => import("./components/admin/Admin"));
const ProductForm = lazy(() => import("./components/ProductForm"));
const TagProducts = lazy(() => import("./components/TagProducts"));
const NewsPage = lazy(() => import("./components/NewsPage"));
const NewsDetail = lazy(() => import("./components/NewsDetail"));
const LoginRegister = lazy(() => import("./components/LoginRegister"));

const useFetch = (url, navigate) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    console.log(`[${new Date().toISOString()}] Bắt đầu fetch dữ liệu từ: ${url}`);
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
        signal: AbortSignal.timeout(10000),
      });
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          console.log(`[${new Date().toISOString()}] Phiên đăng nhập hết hạn hoặc không có quyền, chuyển hướng đến /`);
          localStorage.removeItem("token");
          if (navigate) navigate("/");
          throw new Error("Phiên đăng nhập hết hạn hoặc bạn không có quyền truy cập! Vui lòng đăng nhập lại.");
        }
        throw new Error(`Lỗi: ${response.status}`);
      }
      const data = await response.json();
      console.log(`[${new Date().toISOString()}] Nhận dữ liệu từ ${url}:`, data);
      setData(data);
    } catch (err) {
      console.error(`[${new Date().toISOString()}] Lỗi khi fetch dữ liệu từ ${url}: ${err.message}`);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [url, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error };
};

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cart, setCart] = useState([]);
  const [activeCategory, setActiveCategory] = useState("Tất cả");
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    console.log(`[${new Date().toISOString()}] Load cart từ localStorage`);
    const savedCart = localStorage.getItem("cart");
    if (savedCart) setCart(JSON.parse(savedCart));
  }, []);

  useEffect(() => {
    console.log(`[${new Date().toISOString()}] Đồng bộ cart với localStorage:`, cart);
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const verifyUser = async () => {
        console.log(`[${new Date().toISOString()}] Bắt đầu xác minh token khi khởi động: ${token}`);
        try {
          const response = await fetch("https://savinashop.com/api/users/me", {
            headers: { Authorization: `Bearer ${token}` },
            signal: AbortSignal.timeout(60000),
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Không thể xác minh token!");
          }
          const data = await response.json();
          console.log(`[${new Date().toISOString()}] Xác minh token thành công:`, data);
          setUser({ email: data.email || "Người dùng", role: data.role || "user" });
        } catch (error) {
          console.error(`[${new Date().toISOString()}] Lỗi xác minh user khi khởi động: ${error.message}`);
          localStorage.removeItem("token");
          setUser(null);
          toast.error("Phiên đăng nhập không hợp lệ, vui lòng đăng nhập lại!");
          navigate("/");
        }
      };
      verifyUser();
    } else {
      console.log(`[${new Date().toISOString()}] Không tìm thấy token trong localStorage khi khởi động`);
    }
  }, [navigate]);

  const { data: productData, loading: productsLoading, error: productsError } = useFetch(
    user && (user.role === "admin" || user.role === "staff") ? "https://savinashop.com/api/products?limit=50" : "https://savinashop.com/api/public/products?limit=50",
    navigate
  );

  const products = React.useMemo(() => productData?.products || [], [productData]);

  useEffect(() => {
    if (productsError) {
      console.log(`[${new Date().toISOString()}] Lỗi khi lấy danh sách sản phẩm: ${productsError}`);
      toast.error(productsError);
    } else if (!products.length && !productsLoading) {
      console.log(`[${new Date().toISOString()}] Không có sản phẩm nào để hiển thị`);
    }
  }, [productsError, products, productsLoading]);

  const refreshProducts = () => {
    const token = localStorage.getItem("token");
    const url = token ? "https://savinashop.com/api/products?limit=50" : "https://savinashop.com/api/public/products?limit=50";
    console.log(`[${new Date().toISOString()}] Làm mới danh sách sản phẩm từ: ${url}`);
    fetch(url, { headers: token ? { Authorization: `Bearer ${token}` } : {} })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            console.log(`[${new Date().toISOString()}] Phiên đăng nhập hết hạn khi làm mới sản phẩm, chuyển hướng đến /`);
            localStorage.removeItem("token");
            setUser(null);
            toast.error("Phiên đăng nhập hết hạn, vui lòng đăng nhập lại!");
            navigate("/");
            return { products: [] };
          }
          throw new Error(`Lỗi lấy sản phẩm: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.products && Array.isArray(data.products)) {
          console.log(`[${new Date().toISOString()}] Làm mới danh sách sản phẩm thành công:`, data.products);
        } else {
          console.log(`[${new Date().toISOString()}] Không có sản phẩm nào khi làm mới`);
        }
      })
      .catch((err) => {
        console.error(`[${new Date().toISOString()}] Lỗi khi làm mới danh sách sản phẩm: ${err.message}`);
        toast.error("Không thể tải danh sách sản phẩm!");
      });
  };

  const addToCart = (product, variant, note, quantity) => {
    const cartItem = {
      ...product,
      selectedVariant: {
        _id: variant._id,
        name: variant.name,
        price: variant.price,
        finalPrice: variant.discountedPrice !== undefined && variant.discountedPrice !== null ? variant.discountedPrice : variant.price,
        type: variant.type,
        status: variant.status,
        minQuantity: variant.minQuantity,
        maxQuantity: variant.maxQuantity,
        minOrderValue: variant.minOrderValue,
        salePercentage: variant.salePercentage || 0,
      },
      note: variant.type === "special" ? note : "",
      quantity: quantity || 1,
      cartItemId: `${product._id}-${variant._id}-${Date.now()}`,
    };

    setCart((prevCart) => {
      console.log(`[${new Date().toISOString()}] Thêm sản phẩm mới vào giỏ hàng: productId=${product._id}, variantId=${variant._id}, quantity=${quantity}, finalPrice=${cartItem.selectedVariant.finalPrice}`);
      return [...prevCart, cartItem];
    });
  };

  const handleLogout = () => {
    console.log(`[${new Date().toISOString()}] Đăng xuất user:`, user);
    setUser(null);
    localStorage.removeItem("token");
    setCart([]);
    toast("Đã đăng xuất!");
    refreshProducts();
  };

  const openZaloChat = () => {
    window.open("https://zalo.me/123456789", "_blank");
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Toaster />
      {!location.pathname.startsWith("/admin") && (
        <img
          src={zaloIcon}
          alt="Chat Zalo"
          className="zalo-button"
          onClick={openZaloChat}
        />
      )}
      <Header
        key={user ? user.email : "no-user"}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        cart={cart}
        user={user}
        handleLogout={handleLogout}
      />
      <Box component="main" sx={{ flexGrow: 1, py: { xs: 2, md: 3 }, px: { xs: 1, md: 0 } }}>
        <Suspense
          fallback={
            <Box sx={{ textAlign: "center", py: 5 }}>
              <Typography variant="body2" sx={{ mt: 2 }}>Đang tải...</Typography>
            </Box>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                productsLoading ? (
                  <Box sx={{ textAlign: "center", py: 5 }}>
                    <Typography variant="body2" sx={{ mt: 2 }}>Đang tải sản phẩm...</Typography>
                  </Box>
                ) : (
                  <Home addToCart={addToCart} activeCategory={activeCategory} setActiveCategory={setActiveCategory} searchQuery={searchQuery} />
                )
              }
            />
            <Route path="/tin-tuc" element={<NewsPage />} />
            <Route path="/news/:slug" element={<NewsDetail />} />
            <Route path="/cart" element={<Cart cart={cart} setCart={setCart} user={user} />} />
            <Route path="/:slug" element={<ProductDetail addToCart={addToCart} />} />
            <Route path="/orders" element={<OrderHistory user={user} />} />
            <Route
              path="/admin/*"
              element={user && (user.role === "admin" || user.role === "staff") ? <Admin refreshProducts={refreshProducts} /> : <Navigate to="/" replace />}
            />
            <Route
              path="/admin/products/add"
              element={user && (user.role === "admin" || user.role === "staff") ? <ProductForm /> : <Navigate to="/" replace />}
            />
            <Route
              path="/admin/products/edit/:id"
              element={user && (user.role === "admin" || user.role === "staff") ? <ProductForm /> : <Navigate to="/" replace />}
            />
            <Route path="/tag/:tag" element={<TagProducts addToCart={addToCart} />} />
            <Route path="/login-register" element={<LoginRegister setUser={setUser} />} />
          </Routes>
        </Suspense>
      </Box>
      <Box component="footer" sx={{ bgcolor: "grey.900", color: "white", py: 3, px: { xs: 2, md: 3 }, mt: "auto" }}>
        <Stack spacing={2} sx={{ textAlign: "center" }}>
          <Typography variant="h6" fontWeight="bold">Savinashop</Typography>
          <Typography variant="body2">Cửa hàng tài khoản số 1 Việt Nam</Typography>
          <Stack spacing={1}>
            <Typography variant="body2">© 2025 Savinashop. All rights reserved.</Typography>
            <Typography variant="body2">Liên hệ: <a href="mailto:support@savinashop.vn" style={{ color: "white" }}>support@savinashop.vn</a></Typography>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
            <IconButton component="a" href="https://facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: "white" }}><i className="bi bi-facebook" style={{ fontSize: "1.5rem" }}></i></IconButton>
            <IconButton component="a" href="https://twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: "white" }}><i className="bi bi-twitter" style={{ fontSize: "1.5rem" }}></i></IconButton>
            <IconButton component="a" href="https://instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: "white" }}><i className="bi bi-instagram" style={{ fontSize: "1.5rem" }}></i></IconButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId="666575462727-apb9hae8vsqiooca4orfa9la21vnpi97.apps.googleusercontent.com">
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;